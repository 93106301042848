.navbar {
  background-color: white;
  font-family: "Open Sans", sans-serif;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  place-items: center;
  padding: 0.8rem 0.8rem;
}

.nav-left {
  display: flex;
  gap: 0.5rem;
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: bolder;
}

.nav-left div .nav-logo {
  color: #2caeba;
  font-family: "The Nautigal", cursive;
  font-weight: 700;
  font-size: 2.2rem;
}

.theme-icon {
  color: #102a42;
  font-size: 1.6rem;
}

.theme-icon:hover,
.nav-logo:hover {
  cursor: url("../../assets/pointer.svg"), auto;
}

.nav-right {
  width: 70%;
}

.nav-links {
  display: flex;
  justify-content: space-between;
}

.nav-link {
  color: #102a42;
  font-size: 1.3rem;
  font-weight: 800;
  letter-spacing: 2px;
  transition: all 0.25s linear;
}

.nav-link:hover {
  color: #2caeba;
  text-decoration: underline 2px #2caeba;
  text-underline-offset: 10px;
  cursor: url("../../assets/pointer.svg"), auto;
}

.active-nav-link {
  text-decoration: underline 2px #102a42;
  text-underline-offset: 10px;
  transition: all 0.25s linear;
}

@media (max-width: 900px) {
  .nav-right {
    display: none;
  }
}
