.blogs {
  padding-top: 4rem;
  padding-bottom: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  gap: 3rem;
  background-color: white;
}

.blogs h1 {
  color: #102a42;
  font-family: "Roboto", sans-serif;
  font-size: 2.4rem;
  letter-spacing: 2.4px;
  text-decoration: underline #2caeba 0.3rem;
  text-underline-offset: 0.7rem;
}

.blog-cards {
  display: flex;
  gap: 2rem;
}

.blog-card {
  background-color: #f1f5f8;
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 1rem;
  padding: 4rem 0.75rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
  width: 600px;
  height: 350px;
  transition: all 1s ease;
}

.blog-card h3 {
  color: #102a42;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.7rem;
  line-height: 3rem;
  transition: all 1s ease;
}

.blog-card p {
  color: #324d67;
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
  width: 90%;
  transition: all 1s ease;
}

.blog-link {
  color: black;
  font-weight: 600;
  font-size: 0.85rem;
  transition: all 1s ease;
}

.blog-card:hover {
  background-color: #2caeba;
  color: hsl(185, 94%, 87%);
}

.blog-card:hover h3 {
  color: white;
}

.blog-card:hover p {
  color: hsl(185, 94%, 87%);
}

.blog-card:hover .blog-link {
  color: whitesmoke;
  font-size: 1rem;
  cursor: url("../../assets/pointer.svg"), auto;
}

@media (max-width: 900px) {
  .blog-cards {
    flex-direction: column;
    place-items: center;
  }

  .blog-card {
    height: 450px;
    justify-content: center;
    width: 90%;
  }

  .blog-card h3 {
    text-align: center;
  }
}
