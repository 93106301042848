.hero {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  place-items: center;
  background-color: #e0fcff;
}

.hero-img-desktop {
  width: 50%;
}

.hero-img-mobile {
  display: none;
}

.intro {
  font-family: "Roboto", sans-serif;
  text-align: center;
  width: 90%;
}

.underline-desktop {
  height: 0.1rem;
  width: 10rem;
  padding: 0.25rem 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: auto;
  margin-right: auto;
  background-color: #2caeba;
}

.underline-mobile {
  display: none;
}

.hero h1 {
  color: #102a42;
  font-size: 4.2rem;
  letter-spacing: 2px;
}

.hero h6 {
  color: #617d98;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 1.5px;
}

.hero-btn {
  background-color: #2caeba;
  border: none;
  color: #bff8fd;
  cursor: url("../../assets/pointer.svg"), auto;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  margin-top: 1rem;
  border-radius: 0.2rem;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 1px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.hero-btn:hover {
  color: hsl(184, 91%, 17%);
  background-color: hsl(184, 80%, 74%);
}

.hero-social-links {
  display: flex;
  justify-content: center;
  place-items: center;
  gap: 1rem;
  font-size: 1.8rem;
  margin-top: 1rem;
}

.hero-social-link {
  color: #102a42;
  transition: all 0.3s ease-in-out;
}

.hero-social-link:hover {
  color: #2caeba;
  cursor: url("../../assets/pointer.svg"), auto;
}

.resume {
  margin-right: 1rem;
}

.resume-download-icon {
  font-size: 1.3rem;
  padding-top: 0.1rem;
}

.resume-link {
  color: #bff8fd;
  display: flex;
  place-items: center;
}

.hero-btn:hover .resume-link {
  color: hsl(184, 91%, 17%);
  transition: all 0.3s linear;
  cursor: url("../../assets/pointer.svg"), auto;
}

@media (max-width: 900px) {
  .hero {
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
  }

  .hero-img-desktop,
  .underline-desktop {
    display: none;
  }

  .underline-mobile {
    display: block;
    height: 0.05rem;
    width: 10rem;
    padding: 0.2rem 0.25rem;
    margin-bottom: 0.25rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.25rem;
    background-color: #2caeba;
  }

  .hero-img-mobile {
    display: block;
    object-fit: cover;
    width: 20rem;
    margin-top: 10px;
  }

  .intro {
    display: flex;
    flex-direction: column;
    place-items: center;
    gap: 5px;
  }

  .hero-btn-container {
    display: flex;
  }

  .hero h1 {
    font-size: 2.9rem;
  }
}
