.error {
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
}
.error h3 {
  color: #222222;
  font-size: 1.2rem;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}
.error button {
  background-color: #2caeba;
  border: none;
  color: #bff8fd;
  cursor: url("../../assets/pointer.svg"), auto;
  padding: 0.75rem 0.75rem;
  font-size: 1rem;
  margin-top: 1rem;
  border-radius: 0.2rem;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 1px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  display: flex;
  place-items: center;
  gap: 1rem;
}
.error button:hover {
  color: hsl(184, 91%, 17%);
  background-color: hsl(184, 80%, 74%);
  cursor: url("../../assets/pointer.svg"), auto;
}
.error-home {
  font-size: 1.5rem;
}
