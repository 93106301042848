.skills-section {
  background-color: white;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  gap: 2rem;
  text-transform: capitalize;
}

.skills-section h1 {
  color: #102a42;
  font-family: "Roboto", sans-serif;
  font-size: 2.4rem;
  letter-spacing: 2.4px;
  text-decoration: underline #2caeba 0.3rem;
  text-underline-offset: 0.7rem;
  position: relative;
}

.categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.3rem;
  place-items: center;
  justify-content: center;
}

.category {
  display: flex;
  place-items: center;
  padding: 10px 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 1.1px;
  color: #222222;
  background: #f1f5f8;
  cursor: url("../../assets/pointer.svg"), auto;
  max-width: 300px;
  transition: all 0.5s ease;
}

.category:hover {
  background: #222222;
  color: #f1f5f8;
}

.filter-icon {
  font-size: 1.3rem;
}

.skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  place-items: center;
  width: 90%;
  gap: 1.5rem;
}

.skill {
  position: relative;
  background-color: #617d98;
  color: #dae2ec;
  padding: 0.5rem 0.5rem;
  letter-spacing: 2px;
  border-radius: 0.3rem;
  transition: all 0.5s ease;
  cursor: url("../../assets/pointer.svg"), auto;
  width: 130px;
  height: 80px;
}

.skill-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  font-size: 3.1rem;
  opacity: 1;
  transition: opacity ease 0.5s;
}

.skill-name {
  opacity: 0;
}

.skill:hover {
  transform: scale(1.1);

  .skill-icon {
    opacity: 0;
    transition: opacity ease 0.5s;
  }

  .skill-name {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    opacity: 1;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    overflow-wrap: break-word;
    transition: opacity ease 0.5s;
  }
}

@media (max-width: 900px) {
  .skills {
    width: 100%;
  }

  .skill {
    width: 100px;
  }

  .skill-icon {
    font-size: 2.9rem;
  }

  .skill:hover {
    .skill-name {
      font-size: 0.7rem;
    }
  }
}
