/* Global styles and styling for parent pages */

/* font-family: "Roboto", sans-serif; */
/* font-family: "Open Sans", sans-serif; */
/* font-family: "Josefin Sans", sans-serif; */

@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto:400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=The+Nautigal:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

body {
  background-color: #f1f5f8;
  transition: all 0.3s linear;
  cursor: url("./assets/cursor.svg"), auto;
}

.dsa,
.contact,
.error,
.projects,
.opensource,
.allissues,
.allprs,
.home {
  background: var(--clr-grey-10);
  min-height: calc(100vh - 9rem);
}

.scroll-to-top-arrow {
  cursor: url("./assets/pointer.svg"), auto !important;
  background-color: hsl(184, 80%, 74%) !important;
}
