.dsa {
  display: flex;
  flex-direction: row;
  justify-content: center;
  place-items: center;
  gap: 5rem;
  width: 100%;
}

.dsa-doughnut {
  width: 500px;
}

.dsastats-details {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  place-items: center;
  gap: 2rem;
}

.dsastats-details a {
  color: black;
}

.dsa-btn-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.dsastats-details .dsa-button {
  width: 200px;
  display: flex;
  justify-content: center;
  place-items: center;
  gap: 2px;
  background-color: #2caeba;
  color: #bff8fd;
  padding: 0.8rem 0.5rem;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.2rem;
}

.dsastats-details .dsa-button:hover {
  color: hsl(184, 91%, 17%);
  background-color: hsl(184, 80%, 74%);
  transition: all 0.3s linear;
  cursor: url("../../assets/pointer.svg"), auto;
}

.achievements {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  font-size: 1.3rem;
  font-family: "Josefin Sans", sans-serif;
  line-height: 35px;
}

.achievement {
  display: flex;
  place-items: center;
  gap: 0.5rem;
}

.gcp-link {
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.gcp-link:hover {
  color: #2caeba;
  cursor: url("../../assets/pointer.svg"), auto;
}

.dsa-bullet {
  font-size: 0.8rem;
  color: #2caeba;
  font-weight: bold;
}

.dsa-bullet-mobile {
  display: none;
}

@media screen and (max-width: 900px) {
  .dsa {
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 5rem;
    margin-top: 2rem;
  }

  .dsa-doughnut {
    width: 350px;
  }

  .dsastats-details {
    flex-direction: column;
  }

  .dsa-btn-container {
    width: 350px;
  }

  .achievements {
    width: 350px;
    font-size: 1.3rem;
    line-height: 30px;
  }

  .dsa-bullet {
    display: none;
  }

  .dsa-bullet-mobile {
    display: inline;
    font-size: 0.9rem;
    color: #2caeba;
    font-weight: bold;
  }

  .gcp-link {
    font-size: 0.9rem;
  }
}
