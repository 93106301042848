.experience {
  background-color: white;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  gap: 2rem;
}
.experience h1 {
  color: #102a42;
  font-family: "Roboto", sans-serif;
  font-size: 2.4rem;
  letter-spacing: 2.4px;
  text-decoration: underline #2caeba 0.3rem;
  text-underline-offset: 0.7rem;
}
.job-section {
  width: 80vw;
  display: flex;
  justify-content: space-between;
}
.btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.job-btn {
  background: transparent;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  width: fit-content;
  text-transform: capitalize;
  font-size: 1.3rem;
  font-weight: 550;
  letter-spacing: 2px;
  padding: 0.75rem 0.75rem;
  transition: all 0.3s ease-in-out;
}
.active-job-btn {
  color: #2caeba;
  text-decoration: underline #2caeba;
  text-underline-offset: 5px;
}
.job-btn:hover {
  color: #2caeba;
  cursor: url("../../assets/pointer.svg"), auto;
}
.job-info {
  width: 60%;
}
.job-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: "Open Sans", sans-serif;
  margin-top: 2rem;
}
.job-details h3 {
  color: #102a42;
  font-size: 1.8rem;
  letter-spacing: 2px;
  font-family: "Roboto", sans-serif;
}
.job-skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 5px;
}
.job-skill {
  color: #617d98;
  background-color: #dae2ec;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-left: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.3rem;
}
.job-dates {
  color: #324d67;
  text-transform: capitalize;
  margin-left: 0.5rem;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 2px;
}
.job-duties {
  color: #324d67;
  margin-left: 1.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  /* place-items: center; */
}
.job-duties li {
  /* display: flex;
  place-items: center; */
  font-size: 1.3rem;
  gap: 0.75rem;
}
.bullet {
  font-size: 0.8rem;
  color: #2caeba;
  font-weight: bold;
}

@media (max-width: 900px) {
  .job-section {
    flex-direction: column;
    place-items: center;
    width: 90vw;
  }
  .btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
  }
  .job-info {
    width: 100%;
    place-self: center;
  }
  .btn-container {
    width: 100vw;
  }
}
